<template>
    <div>
        <div class="hotel-bg-ats">
            <div class="layout">
                <v-container class="cont">
                    <v-row
                    align="center"
                    justify="center"
                    class="inner-cont"
                    no-gutters
                    >
                        <v-card class="pa-5 rounded-xl">
                            <hotelSearch />
                        </v-card>
                    </v-row>
                </v-container>
            </div>
        </div>
    </div>
</template>

<script>
import hotelSearch from '../components/hotelSearch.vue'
export default {
  title: '-Hotels search',
  components: {
    hotelSearch
  }
}
</script>

<style>
    .cont, .inner-cont {
        height: calc(100vh - 65px);
    }
    .hotel-bg {
        height: 100%;
        min-height: calc(100vh - 65px);
        background: url('http://dev-booking-engine.fare33.com/images/assets/hotel.jpeg');
        background-size: cover;
        background-position: center center;
    }
    .hotel-bg-ats {
        height: 100%;
        min-height: calc(100vh - 54px);
        background: url('http://dev-booking-engine.fare33.com/images/assets/hotel.jpeg');
        background-size: cover;
        background-position: center center;
    }
    .layout {
        height: 100%;
        min-height: calc(100vh - 65px);
        background-color: rgba(0, 0, 0, 0.1);
    }
</style>
